import mountains from '../assets/images/background.jpeg';
import trees from '../assets/images/background-2.jpg';
import pumpkins from '../assets/images/pumpkins.jpg';

const Banner = () => {
    return (
        <div className='bg-image-wrapper'>
            <div className='tinter' />
            {/* <img className='bg-image'
                    src={mountains} alt='Mountains' /> */}
            <img className='bg-image'
                    src={pumpkins} alt='Pumpkins' />
        </div>
    )
}

export default Banner;