import { db, functions, storage } from './fbConfig';
import {
    collection,
    getDocs,
    doc,
    setDoc,
    getDoc,
    addDoc,
    updateDoc,
    arrayUnion,
    onSnapshot,
    deleteDoc,
    query,
    where,
    Timestamp,
    deleteField,
    orderBy,
    writeBatch,
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';

// Convert MemberDashboard dob string to age (int)
export const getAge = (dobString) => {
    const split = dobString.split('-');
    const birthday = new Date(split[2], split[0], split[1]);
    const diffMS = Date.now() - birthday;
    const ageDate = new Date(diffMS); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

// Convert to firebase timestamp
export const convertToFBTimestamp = (date, time) => {
    if (!date || !time) return null;
    // Parse the date and time strings
    let [mm, dd, yyyy] = date.split('-').map(Number);
    let [hh, min] = time.split(':').map(Number);
    // Create a JavaScript Date object
    let dateObj = new Date(yyyy, mm - 1, dd, hh, min); // Note: Month is 0-indexed in JavaScript
    // Convert to Firestore Timestamp and return
    return Timestamp.fromDate(dateObj);
};

// Convert firebase date
export const convertFromFBTimestamp = (date) => {
    const dateObj = date.toDate();

    // Extracting date
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const year = dateObj.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;

    // Extracting time
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    let formattedTime = `${hours}:${minutes}`;

    return {
        date: formattedDate,
        time: formattedTime,
    };
};

export const FBTimestampToMillis = (ts) => {
    return ts.toMillis();
};

// Get time (MS)
export const getCurrentTimeMillis = () => {
    return Timestamp.now().toMillis();
};

// Update event
export const updateEvent = async (uid, event) => {
    const docRef = doc(db, 'events', uid);
    const data = event;
    await updateDoc(docRef, data);
};

// Create event
export const createEvent = async (event) => {
    const colRef = collection(db, 'events');
    const data = event;
    await addDoc(colRef, data);
};

// Remove event
export const removeEvent = async (id) => {
    const docRef = doc(db, 'events', id);
    await deleteDoc(docRef);
};

// TODO: Conflicting definition with getUserDetails
export const getUserInfoFromUID = async (uid) => {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    return data;
};

// Get events
export const getEvents = async (user, showAll = false) => {
    const col = collection(db, 'events');

    let q;
    if (showAll) {
        // If showAll is true, get all events without time filter
        q = query(col, orderBy('date'));
    } else if (
        user.details &&
        (user.details.permissions > 1 || user.details.hasEarlyAccess)
    ) {
        // Staff see event 14 days early
        const tsToMillis = Timestamp.now().toMillis();
        const shift = 14 * 24 * 60 * 60 * 1000;
        const compareDate = new Date(tsToMillis + shift);
        q = query(col, where('open', '<=', compareDate), orderBy('open'));
    } else if (
        user.details &&
        (user.details.permissions > 0)
    ) {
        // If showAll is false and not staff, apply the time filter
        const tsToMillis = Timestamp.now().toMillis();
        const compareDate = new Date(tsToMillis);
        q = query(col, where('open', '<=', compareDate), orderBy('open'));
    } else {
        // They're not a member, return all events of type party (in time order)
        const tsToMillis = Timestamp.now().toMillis();
        const compareDate = new Date(tsToMillis);
        q = query(col, 
            where('open', '<=', compareDate), 
            where('type', '==', 'party'),
            orderBy('open')
        );
    }

    const querySnapshot = await getDocs(q);
    let events = [];
    querySnapshot.forEach((doc) => {
        const event = doc.data();
        event.uid = doc.id;
        if (showAll) {
            events.push(event);
        } else if (event.live) {
            if (event.staffOnly) {
                if (user && user.details && user.details.permissions > 1) {
                    events.push(event);
                }
            } else {
                events.push(event);
            }  
        }
    });

    return events;
};

// Get member data
export const getMemberData = async () => {
    const col = collection(db, 'users');
    const q = query(col, where('permissions', '>=', 1));
    const querySnapshot = await getDocs(q);

    let members = [];
    querySnapshot.forEach((doc) => {
        const member = doc.data();
        members.push({
            name: member.name,
            uid: doc.id,
            email: member.email,
            dob: member.dob,
            phone: member.phone,
            emergency: member.emergency,
            schedule: member.schedule,
            ikon: member.ikon,
            hasMemberTee: member.hasMemberTee,
            hasEarlyAccess: member.hasEarlyAccess,
            hasPaidDuesEarly: member.hasPaidDuesEarly
        });
    });

    return members;
};

// // Clear IKON Codes
// export const clearIKONCodes = async (user) => {
//     const col = collection(db, 'users');

//     const snapshot = await getDocs(col);
//     const updateUserPromises = snapshot.docs.map(userDoc => {
//         // Assuming the 'IKON' field is directly on the user document
//         console.log(userDoc.id)
//         return updateDoc(doc(db, 'users', userDoc.id), {
//             ikon: deleteField()
//         });
//     });

//     // Wait for all the user documents to be updated
//     await Promise.all(updateUserPromises);
//     console.log('All users’ IKON codes have been cleared.');
// };

// Set all member 'permissions' to 0 except those who have paid dues early
// Clear all IKON codes, i.e. delete the field 'ikon'
// Delete the field 'hasPaidDuesEarly'
// Set 'hasMemberTee' to false
// export const updateUserPermissionsAndFields = async () => {
//     // Reference to the users collection
//     const usersCol = collection(db, 'users');
//     const usersSnapshot = await getDocs(usersCol);

//     const batch = writeBatch(db); // Firestore batch for atomic updates

//     usersSnapshot.forEach((userDoc) => {
//         const userRef = userDoc.ref;
//         const userData = userDoc.data();

//         // Set 'permissions' to 0 unless 'hasPaidDuesEarly' is true
//         if (!userData.hasPaidDuesEarly && userData.permissions <= 1) {
//             batch.update(userRef, {
//                 permissions: 0,
//             });
//         }

//         // Clear the 'ikon' field if it exists
//         if (userData.ikon) {
//             batch.update(userRef, {
//                 ikon: deleteField(), // Remove the 'ikon' field
//             });
//         }

//         // Delete the 'hasPaidDuesEarly' field
//         if ('hasPaidDuesEarly' in userData) {
//             batch.update(userRef, {
//                 hasPaidDuesEarly: deleteField(), // Remove the 'hasPaidDuesEarly' field
//             });
//         }

//         // Set 'hasMemberTee' to false
//         batch.update(userRef, {
//             hasMemberTee: false,
//         });
//     });

//     // Commit the batch operation
//     await batch.commit();
//     console.log('User permissions and fields updated successfully.');
// };

// Helper function to parse CSV
const parseCSV = (filePath) => {
    return new Promise((resolve, reject) => {
        const results = [];
        fs.createReadStream(filePath)
            .pipe(csv())
            .on('data', (data) => results.push(Object.values(data)))
            .on('end', () => resolve(results))
            .on('error', (error) => reject(error));
    });
};


// Add new user to Firestore
export const uploadUser = async (user) => {
    const docRef = doc(db, 'users', user.uid);
    const data = {
        email: user.email,
        name: user.displayName,
        permissions: 0,
    };
    await setDoc(docRef, data);
};

// Add new user to Stripe customer list
export const handleNewUser = async (user) => {
    const createCustomer = httpsCallable(functions, 'createCustomer');
    createCustomer({
        email: user.email,
        name: user.displayName,
    }).then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
    });
    uploadUser(user);
};

// Get user details
export const getUserDetails = async (user) => {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    // Check if user has paid dues
    if (data && data.permissions === 0 && !data.removed) {
        if (await userHasPaidMembership(user)) {
            data.permissions = 1;
            updateParam(user, 'permissions', 1);
        }
    }
    // Check if user has paid 24/25 dues - update hasPaidDuesEarly
    // if (data && !data.hasPaidDuesEarly && !data.removed) {
    //     if (await userHasPaidMembership(user)) {
    //         data.hasPaidDuesEarly = true;
    //         updateParam(user, 'hasPaidDuesEarly', true);

    //         if (data.permissions === 0) {
    //             data.permissions = 1;
    //             updateParam(user, 'permissions', 1);
    //         }
    //     }
    // }
    return data;
};

// Check if user has paid for membership
export const userHasPaidMembership = async (user) => {
    const priceRef = doc( 
        db,
        'products/prod_PO8DETk33FIHYL/prices', // 24-25 membership
        'price_1P3R7wA8yISC5yksSM5p12LW'
    );
    // Reference to logged in user payments
    const col = collection(db, `customers/${user.uid}/payments`);
    const q = query(col, where('prices', 'array-contains', priceRef));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
};

// Check if user has paid for event at any price
export const userHasPaidForEvent = async (user, event) => {

    // Get all prices associated with the product
    const pricesCol = collection(db, `products/${event.prod_id}/prices`);
    const pricesSnapshot = await getDocs(pricesCol);

    // Extract price refs
    const priceRefs = pricesSnapshot.docs.map((doc) => doc.ref);

    // Reference to logged in user payments
    const paymentsCol = collection(db, `customers/${user.uid}/payments`);

    // Check if user has made a payment for any of the price IDs
    for (let priceRef of priceRefs) {
        const q = query(
            paymentsCol,
            where('prices', 'array-contains', priceRef)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            return true; // User has paid for one of the prices
        }
    }

    return false; // User has not paid for any of the prices
};

// Get rid of unpaid users from an event
export const cleanUnpaidUsersFromEvent = async (event) => {
    const eventRef = doc(db, 'events', event.uid);
    const eventSnap = await getDoc(eventRef);

    if (!eventSnap.exists()) {
        console.error('Event document does not exist.');
        return;
    }

    const eventData = eventSnap.data();
    if (!eventData.responses || typeof eventData.responses !== 'object') {
        console.error('No responses found for this event.');
        return;
    }

    const batch = writeBatch(db);  // Firestore batch to atomically update event responses

    for (let userId in eventData.responses) {
        const userResponse = eventData.responses[userId];
        
        // Check if the user has paid for the event
        const userHasPaid = await userHasPaidForEvent({ uid: userId }, event);
        
        if (!userHasPaid) {
            // If the user has not paid, remove their response
            console.log(`${userResponse.signature}: deleting user response`);
            const responsePath = `responses.${userId}`;
            batch.update(eventRef, {
                [responsePath]: deleteField(),
            });
        } else if (!userResponse.paid) {
            console.log(`${userResponse.signature}: setting paid to true`);
            const responsePath = `responses.${userId}.paid`;
            batch.update(eventRef, {
                [responsePath]: true,
            });
        }
    }

    // Commit the batch operation to remove unpaid users
    await batch.commit();
    console.log('Unpaid users removed from event responses.');
};


// Upload event form response
export const uploadEventResponse = async (user, event, response) => {
    const docRef = doc(db, 'events', event.uid);
    // Create a response path using the user's UID
    const responsePath = `responses.${user.uid}`;
    // Update the specific user's response in the event document
    await updateDoc(docRef, {
        [responsePath]: response,
    });
};

// Update the 'paid' status of a specific user response for an event
export const setUserHasPaidForEvent = async (user, event) => {
    const eventRef = doc(db, 'events', event.uid);

    const eventDoc = await getDoc(eventRef);
    if (!eventDoc.exists()) {
        console.error('Event document does not exist.');
        return;
    }

    const eventData = eventDoc.data();
    if (!eventData.responses || !eventData.responses[user.uid]) {
        console.error('Response for the given user UID does not exist.');
        return;
    }

    // Update only the 'paid' attribute for the specified user's response
    const responsePath = `responses.${user.uid}.paid`;
    await updateDoc(eventRef, { [responsePath]: true });
};

// Calculate the number of attendees who cannot drive
export const countGeneral = async (event) => {
    const eventRef = doc(db, 'events', event.uid);
    const eventSnap = await getDoc(eventRef);

    if (!eventSnap.exists()) {
        console.error('Event document does not exist.');
        return 0;
    }

    const eventData = eventSnap.data();
    if (!eventData.responses || typeof eventData.responses !== 'object') {
        return 0;
    }

    return Object.values(eventData.responses).reduce((count, response) => {
        if (!response.can_drive) {
            count += 1;
        }
        return count;
    }, 0);
};

// Calculate the number of attendees who can drive
export const countDrivers = async (event) => {
    const eventRef = doc(db, 'events', event.uid);
    const eventSnap = await getDoc(eventRef);

    if (!eventSnap.exists()) {
        console.error('Event document does not exist.');
        return 0;
    }

    const eventData = eventSnap.data();
    if (!eventData.responses || typeof eventData.responses !== 'object') {
        return 0;
    }

    return Object.values(eventData.responses).reduce((count, response) => {
        if (response.can_drive === 1) {
            count += 1;
        }
        return count;
    }, 0);
};

// Create stripe checkout and redirect
export const createCheckoutSession = async (user, price_id) => {
    const docRef = await addDoc(
        collection(db, `customers/${user.uid}/checkout_sessions`),
        {
            price: price_id,
            mode: 'payment',
            success_url: 'https://laboardclub.com/dashboard',
            cancel_url: 'https://laboardclub.com/dashboard',
        }
    );

    onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
            // Show an error to your customer and inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
            return error;
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });
};

/* Account verifcation functions */

// Upload file to firebase and sync to firestore
export const uploadSchedule = async (user, file) => {
    // Upload file to firebase storage
    const storageRef = ref(storage, `/schedules/23-24/${user.uid}`);
    await uploadBytesResumable(storageRef, file);
    const url = await getDownloadURL(storageRef);

    // Upload url to user
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
        schedule: url,
    });

    return url;
};

export const updateParam = async (user, param, val) => {
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
        [param]: val,
    });
};

// DEPRECIATED
// Get all data for admin panel
export const handleGetMemberData = async (user) => {
    const getMemberData = httpsCallable(functions, 'getMemberData');
    const result = await getMemberData();
    return result.data;
};

export const handleUpdateIKON = async (uid, ikon) => {
    const updateIKON = httpsCallable(functions, 'updateIKON');
    await updateIKON({
        uid: uid,
        ikon: ikon,
    });
};

// Bug fix: Check if user exists in users
export const userDoesExist = async (user) => {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return true;
    }
    return false;
};
